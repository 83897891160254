body .documentation {
    /*font-family: 'Open Sans', serif;*/
    padding-top: 54px;
    /* color: #868e96; */
}
@media (min-width:992px) {
    body .documentation {
        padding-top: 0;
        padding-left: 17rem;
    }
}
.documentation h1, h2, h3, h4, h5, h6 {
     /*font-family: 'Saira Extra Condensed', serif;*/
     font-weight: 700;
     /*text-transform: uppercase;*/
     color: #343a40;
 }
.documentation h1 {
    font-size: 6rem;
    line-height: 5.5rem;
}
.documentation h2 {
    font-size: 2.5rem;
}
.documentation .subheading {
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Saira Extra Condensed', serif;
    font-size: 1.35rem;
}
.documentation .list-social-icons a {
    color: #495057;
}
.documentation .list-social-icons a:hover {
    color: #bd5d38;
}
.documentation .list-social-icons a .fa-lg {
    font-size: 1.75rem;
}
.documentation .list-icons {
    font-size: 3rem;
}
.documentation .list-icons .list-inline-item i:hover {
    color: #bd5d38;
}
.documentation #sideNav .navbar-nav .nav-item .nav-link {
    font-weight: 600;
    text-transform: uppercase;
}
.documentation #navbarSupportedContent .main-menu {
    padding: 0 5% 0 0%;
    margin: 0 auto;
}
.documentation #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
    cursor: pointer;
}
.documentation #sideNav .back-home {
    border: 1px solid #ffffff;
    color: #ffffff;
}
.documentation #sideNav .back-home:hover {
    background: #ffffff;
    color: #000000;
    border: 1px solid #868e96;
}
.documentation #sideNav .back-home i{
    padding-right: 10px;
}
.documentation #navbarSupportedContent .main-menu .main-back {
    margin: 25px 0px 0 -50px;
}
@media (min-width:992px) {
    .documentation #sideNav {
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 17rem;
        height: 100vh;
    }
    .documentation #sideNav .navbar-brand {
        display: flex;
        margin: auto auto 0;
        padding: .5rem;
    }
    .documentation #sideNav .navbar-brand .img-profile {
        max-width: 10rem;
        max-height: 10rem;
        border: .5rem solid rgba(255, 255, 255, .2);
    }
    .documentation #sideNav .navbar-collapse {
        display: flex;
        align-items: flex-start;
        flex-grow: 0;
        width: 100%;
        margin-bottom: auto;
    }
    .documentation #sideNav .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%}
    .documentation #sideNav .navbar-collapse .navbar-nav .nav-item {
        display: block;
    }
    .documentation #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
        display: block;
    }
}
.documentation section.resume-section {
     border-bottom: 1px solid #dee2e6;
     padding-top: 5rem!important;
     padding-bottom: 5rem!important;
 }
.documentation section.resume-section .resume-item .resume-date {
    min-width: none;
}
@media (max-width:992px) {
    .documentation h1 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .documentation #navbarSupportedContent .main-menu .main-back {
        margin: 25px 0px 0 0px;
    }
    .documentation h2 {
        font-size: 1.5rem;
    }
    .documentation section.resume-section {
        padding: 0 15px;
    }
    /*.documentation .collapse:not(.show) {*/
        /*display: block;*/
    /*}*/
    .documentation #navbarSupportedContent .main-menu {
        padding: 0 5%;
        margin: 0 auto;
    }
}
@media (min-width:768px) {
    .documentation section.resume-section {
        min-height: 100vh;
        /*margin-top: -20px;*/
    }
    .documentation section.resume-section:first-child {
        /*margin-top: 20px;*/
    }
    .documentation section.resume-section .resume-item .resume-date {
        min-width: 18rem;
    }
}
@media (min-width:992px) {
    .documentation section.resume-section {
        padding-top: 0rem!important;
        padding-bottom: 0rem!important;
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
}
@media (max-width:768px) {
    .documentation section.resume-section {
        min-height: 100vh;
    }
}
.documentation .bg-primary {
     background-color:  #17A2B8!important;
 }
.documentation .text-primary {
    color:  #17A2B8!important;
}
.documentation a {
    color:  #17A2B8;
}
.documentation a:active, a:focus, a:hover {
    color: #824027;
}