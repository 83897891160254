.InputPopover__root___3Hpj9 {
    top: calc(100% + 41px);
    left: -170px;
    width: 246px;
    z-index: 9999;
}
.InputPopover__root___3Hpj9:after, .InputPopover__root___3Hpj9:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: initial;
    right: 55px;
}
.RichTextEditor__root___2QXK- {
	height:600px;
    border:none;
	overflow-y:scroll;
}

@media only screen and (max-width: 1300px) {
    .InputPopover__root___3Hpj9 {
        top: calc(100% + 75px);
        left: -140px;
        width: 246px;
        z-index: 9999;
    }
}
@media only screen and (max-width: 992px) {
    .InputPopover__root___3Hpj9 {
        top: calc(100% + 35px);
        left: -140px;
        width: 246px;
        z-index: 9999;
    }
    .InputPopover__root___3Hpj9:after, .InputPopover__root___3Hpj9:before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        left: initial;
        right: 90px;
    }
}
@media only screen and (max-width: 520px) {
    .InputPopover__root___3Hpj9 {
        top: calc(100% + 35px);
        right: -40px;
        width: 246px;
        z-index: 9999;
        left: inherit;
    }
}
@media only screen and (max-width: 460px) {
    .content-responsive-editer {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .InputPopover__root___3Hpj9 {
        top: calc(100% + 45px);
        right: -150px;
        width: 246px;
        z-index: 9999;
        left: inherit;
    }
    .InputPopover__root___3Hpj9:after, .InputPopover__root___3Hpj9:before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        left: initial;
        right: 160px;
    }
}