
.documentation #navbarSupportedContent .main-menu li {
	list-style-type: none;
}
.documentation #navbarSupportedContent .main-menu li p {
	text-align: left;
	color:#fff;
}
.documentation #navbarSupportedContent .main-menu li ul {
	padding-left: 10px;
}
.documentation #navbarSupportedContent .main-menu li ul .nav-item a {
	text-align: left;
}
.documentation .fixed-top {
	background-color: #17A2B8;
}
.documentation .documentation-user-registration {
	background-color: #BEE5EB;
}
#root {
	background-color: #BEE5EB !important;
}
