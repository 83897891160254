.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #2fb0ee;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2fb0ee, 0 0 5px #2fb0ee;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #2fb0ee;
  border-left-color: #2fb0ee;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*custom css datetime picker*/
.custom-form-telephone .react-datepicker-wrapper{
  width: 100%;
}
.custom-form-telephone .react-datepicker__input-container{
  width: 100%;
}

.custom-form-telephone .react-datepicker-popper {
  z-index: 40;
}


.modal_report .custom-width-poper{
  width : 320px;
  z-index: 3333;
}

.min-width-poper{
  width : 100px;
  z-index: 3333;
}

.font-size-16 {
  font-size: 16px;
}

.calling0 {
  background: #A10559 !important
}

.whites-custom2 {
  background-color: #495057 !important;
  color: #fff;
}

.whites-custom1 {
  background-color: #DC3545 !important;
  color: #fff;
}


.whites-custom0 {
  background-color: #A10559 !important;
  color: #fff;
}